<template>
  <div>
    <b-navbar>
      <b-row class="m- w-100">
        <b-col>  <b-navbar-brand class="page-title"> REPORTS </b-navbar-brand></b-col>
        <b-col cols="auto">
<div class="sidebar-width text-right">

         <button  class="btn-opt primary">
          <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
          Refresh
        </button>
           <button class="btn-opt primary">
           <b-icon-printer></b-icon-printer> Print
           </button>
           <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button>
    
  </div>
        </b-col>
      </b-row>
    
      
    </b-navbar>
    <div class="container-fluid px-2">
      <b-nav pills class="ml-2 mb-2">
      <b-nav-item :active="$route.name=='produce'" :link-classes="{'text-dark':!($route.name==='produce')}" to="/reports">Produce</b-nav-item>
      <b-nav-item :active="$route.name=='farm_inputs'" :link-classes="{'text-dark':!($route.name=='farm_inputs')}" to="/reports/farm_inputs">Farm Inputs</b-nav-item>
      <b-nav-item :active="$route.name=='training'" :link-classes="{'text-dark':!($route.name=='training')}" to="/reports/training">Training</b-nav-item>
      <!-- <b-nav-item :active="$route.name=='sales'" :link-classes="{'text-dark':!($route.name=='sales')}" to="/reports/sales">Sales</b-nav-item> -->
    </b-nav>
 
    <router-view></router-view>
    </div>
    
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>